<!-- 语法 -->
<template>
    <div class="">
        <div :class="fu3[0].wanfa=='fc3d'?'fc3d':'no'">
            <div>
                <img src="@/assets/jpg/fc3d.jpg">
            </div>
            （一）单选投注<br>
            单选：单注奖金固定为1040元。<br>
            （二）组选投注<br>
            1. 组选3：单注奖金固定为346元。<br>
            2. 组选6：单注奖金固定为173元。<br>
            （三）1D投注：<br>
            1D：单注奖金固定为10元。<br>
            （四）猜1D投注：<br>
            1. 猜中1：单注奖金固定为2元；<br>
            2. 猜中2：单注奖金固定为12元；<br>
            3. 猜中3：单注奖金固定为230元。<br>
            （五）2D投注：<br>
            2D：单注奖金固定为104元。<br>
            （六）猜2D投注：<br>
            1. 两同号：单注奖金固定为37元；<br>
            2. 两不同号：单注奖金固定为19元。<br>
            （七）通选投注：<br>
            1. 通选1：单注奖金固定为470元；<br>
            2. 通选2：单注奖金固定为21元。<br>
            （八）和数投注：<br>
            1. 和数0或27：单注奖金固定为1040元；<br>
            2. 和数1或26：单注奖金固定为345元；<br>
            3. 和数2或25：单注奖金固定为172元；<br>
            4. 和数3或24：单注奖金固定为104元；<br>
            5. 和数4或23：单注奖金固定为69元；<br>
            6. 和数5或22：单注奖金固定为49元；<br>
            7. 和数6或21：单注奖金固定为37元；<br>
            8. 和数7或20：单注奖金固定为29元；<br>
            9. 和数8或19：单注奖金固定为23元；<br>
            10. 和数9或18：单注奖金固定为19元；<br>
            11. 和数10或17：单注奖金固定为16元；<br>
            12. 和数11或16：单注奖金固定为15元；<br>
            13. 和数12或15：单注奖金固定为15元；<br>
            14. 和数13或14：单注奖金固定为14元。<br>
            （九）包选投注：<br>
            1. 包选三：<br>
            （1）全中：单注奖金固定为693元；<br>
            （2）组中：单注奖金固定为173元。<br>
            2. 包选六：<br>
            （1）全中：单注奖金固定为606元；<br>
            （2）组中：单注奖金固定为86元。<br>
            （十）猜大小投注：<br>
            猜大小：单注奖金固定为6元。<br>
            （十一）猜三同投注：<br>
            猜三同：单注奖金固定为104元。<br>
            （十二）拖拉机投注：<br>
            拖拉机：单注奖金固定为65元。<br>
            （十三）猜奇偶投注：<br>
            猜奇偶：单注奖金固定为8元。<br>
            （一）单选投注：是指对三个号码以唯一的排列方式进行投注。<br>

            （二）组选投注：是指将三个号码的所有排列方式作为一注投注号码进行投注。如果一注组选的三个号码中有两个号码相同，则包括三种不同的排列方式，称为“组选3”；如果一注组选的三个号码各不相同，则包括六种不同的排列方式，称为“组选6”。<br>

            （三）1D投注：是指对百位、十位或个位中某一特定位置上的号码进行投注。<br>

            （四）猜1D投注：是指对百位、十位或个位中任意一个位置上的号码进行投注。<br>

            （五）2D投注：是指对百位和十位、十位和个位或百位和个位号码，以唯一的排列方式进行投注。<br>

            （六）猜2D投注：是指对百位、十位或个位中任意两个位置上的号码进行投注。<br>

            （七）通选投注：是指对三个号码以唯一的排列方式进行投注。<br>

            （八）和数投注：是指对三个号码相加之和进行投注。<br>

            （九）包选投注：是指同时用单选和组选的方式对三个号码进行投注。如果三个号码中有两个号码相同，则包括三种不同的排列方式，称为“包选3”；如果三个号码各不相同，则包括六种不同的排列方式，称为“包选6”。<br>

            （十）猜大小投注：是指对三个号码相加之和的大、小性质进行投注。其中，三个号码相加之和在19（含）至27（含）之间时为大，在0（含）至8（含）之间时为小。<br>

            （十一）猜三同投注：是指对全部三个相同的号码进行投注。<br>

            （十二）拖拉机投注：是指对全部以升序或降序连续排列的号码进行投注（890、098、901、109除外）。<br>

            （十三）猜奇偶投注：是指对全部三个号码的奇数、偶数性质进行投注。其中，1、3、5、7、9为奇，0、2、4、6、8为偶。<br>
        </div>
        <div :class="fu3[0].wanfa=='pl35'?'pl35':'no'">
            <div>
                <img src="@/assets/jpg/pl35.jpg">
            </div>

            (一)直选投注：所选3位数以唯一排列方式作为一注的投注。<br>

            (二)组选投注：所选3位数以所有排列方式作为一注的投注。具体分为：<br>

            组选6：如果一注组选投注的3位数中每位数字各不相同，则有6种不同的排列方式，有6次中奖机会；<br>

            组选3：如果一注组选投注的3位数中有2位数字相同，则有3种不同的排列方式，有3次中奖机会。<br>
            (一)直选投注：<br>

            单注奖金固定为1040元。<br>

            (二)组选投注：<br>

            组选6：单注奖金固定为173元；<br>

            组选3：单注奖金固定为346元。<br>
            排列五<br>
            “排列5”设1个奖级，为固定奖。<br>
            一等奖，单注固定奖金100000元。<br>
            一等奖，所选号码与中奖号码全部相同且顺序一致。例如：中奖号码为43751，则排列5的中奖结果为：43751。<br>
        </div>
        <div :class="fu3[0].wanfa=='qxc'?'qxc':'no'">
            <img src="@/assets/jpg/qxc.jpg">
        </div>
    </div>
</template>

<script>
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    //import api from '@/js/method'
    export default {
        name: '',
        components: {

        },
        data() { // 数据源
            return {
            }
        },
        mounted() {

        },
        computed: {

        },
        methods: { // 函数体部分，js的主要逻辑控制               
        },
        props: ["fu3"],
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .fc3d {
        font-size: 15px;
    }

    .pl35 {
        font-size: 15px;
    }

    .no {
        display: none;
    }
</style>